<template>
  <div>
      <v-main>
        <v-container fluid>
        <v-row class="justify-content-center row" v-if="!isLogin">
            <v-card class="card pa-10">
                <v-text-field
                    label="username"
                    v-model="username"
                >
                </v-text-field>
                <v-text-field
                    label="password"
                    v-model="password"
                    type="password"
                >
                </v-text-field>
                <v-btn color="primary" @click="login()">Masuk</v-btn>
            </v-card>
          </v-row>
          <v-row class="justify-content-center row" v-else>
            <v-card class="card pa-10">
                <code>DEPLOY</code>
                <v-row>
                    <v-col md="6">
                        <v-btn color="success" width="200" @click="deploy('staging')">
                            <span v-show="!isLoadStag">STAGING</span>
                            <b-spinner v-show="isLoadStag" small></b-spinner>
                        </v-btn>
                    </v-col>
                    <v-col md="6">
                        <v-btn color="primary" width="200" @click="deploy('production')">
                            <span v-show="!isLoadProd">PRODUCTION</span>
                            <b-spinner v-show="isLoadProd" small></b-spinner>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
          </v-row>
          <v-row justify="center">
                <v-dialog
                v-model="dialog"
                persistent
                max-width="300"
                >
                <v-card>
                    <v-card-title class="headline">
                    DEPLOY PRODUCTION
                    </v-card-title>
                    <v-card-text>Konfirmasi deploy pada <code>PRODUCTION</code> ?</v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="dialog = false, isLoadProd = false"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="deployProd('production')"
                    >
                        Konfirmasi
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
            <v-row justify="center">
                <h2 class="headline mt-3" v-if="statusDeploy === 'SUCCESS'">
                    DEPLOY SUKSES
                </h2>
                <h2 class="headline mt-3" v-else-if="statusDeploy === 'FAILED'">
                    DEPLOY GAGAL
                </h2>
            </v-row>
        </v-container>
      </v-main>
  </div>
  
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      username: '',
      password: '',
      isLogin: false,
      isLoadStag: false,
      isLoadProd: false,
      dialog: false,
      statusDeploy: ''
    }
  },
  methods:{
    login(){
        if (this.username !== '' || this.password !== '') {
            if (this.username === 'malikchandr' && this.password === '442288') {
                this.isLogin = true
            }
        }
    },
    deploy(target){
        if (target === 'staging') {
            this.isLoadStag = true
            this.processDeploy(target)
        }else{
            this.isLoadProd = true
            this.dialog = true
        }
    },
    deployProd(target){
        this.dialog = false
        this.processDeploy(target)
    },
    processDeploy(target){
        this.$root.api.sentAPI(
        {
        method: 'get',
        api: 'http://localhost:1925/deploy-bufu?target='+target
        })
        .then(res => {
            this.statusDeploy = res
            this.isLoadStag = false
            this.isLoadProd = false
        })
        .catch((err) => {
            console.log(err)
        })
    }
  },
  mounted() {
    
  },
}
</script>
<style scoped>
.card {
  width: 500px;
  text-align: left;
}
</style>
